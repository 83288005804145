<template>
  <a :href="link">
    <div
      class="card flex max-w-lg rounded-md overflow-hidden shadow-lg select-none bg-white  opacity-100 hover:opacity-80 transform duration-150 ease-in-out hover:translate-x-4 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
      @click="displayLoading()"
    >
      <div class="w-32 md:w-64 h-20 md:h-36">
        <img
          class="w-full h-full object-cover pointer-events-none"
          :src="'' + country + '.svg'"
          alt="Country"
        />
      </div>
      <div class="px-4 py-2 w-52 md:w-64 ">
        <div
          class="text-gray-800 dark:text-gray-200 font-bold text-sm md:text-xl mb-2"
        >
          {{ title }}
        </div>
        <p class="text-gray-700 dark:text-gray-300 text-xs md:text-base ">
          {{ desc }}
        </p>
      </div>
    </div>
    <div class="" v-if="loadingDisplayed"><Loading :msg="redirect" /></div>
  </a>
</template>

<script>
import firebase from 'firebase/app'
import Loading from './Loading.vue'
export default {
  name: 'Card',
  components: {
    Loading
  },
  data() {
    return {
      loadingDisplayed: false
    }
  },
  props: {
    title: String,
    desc: String,
    link: String,
    country: String,
    redirect: String
  },
  methods: {
    displayLoading() {
      this.loadingDisplayed = !this.loadingDisplayed
      firebase.analytics()?.logEvent('survey-language', {
        surveyLanguage: this.country
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
