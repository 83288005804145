<template>
  <div id="app" class="bg-gray-100 dark:bg-gray-900 ">
    <div
      class="targeted-content min-h-screen text-2xl flex flex-col justify-evenly items-center z-0"
      v-if="hasSupportedLanguages()"
    >
      <Intro class="items-start mb-4" :title="$t('intro.title')" :desc="$t('intro.desc')" :desc2="$t('intro.desc2')" />

      <div class="targeted-content__choices">
        <Card
          class="mb-4 cursor-pointer"
          :link="$t('card.link')"
          :title="$t('card.title')"
          :desc="$t('card.desc')"
          :country="$t('card.country')"
          :redirect="$t('card.redirect')"
        />
        <div class="mt-10 mb-4 text-gray-800 dark:text-gray-200 text-base md:text-2xl">
          {{ $t('intro.otherLanguages') }}
        </div>

        <div class="flex flex-col">
          <Card
            v-if="otherLanguages.includes('de')"
            class="mb-4 cursor-pointer"
            :link="de['card.link']"
            :title="de['card.title']"
            :desc="de['card.desc']"
            country="de"
            :redirect="de['card.redirect']"
          />
          <Card
            v-if="otherLanguages.includes('en')"
            class="mb-4 cursor-pointer"
            :link="en['card.link']"
            :title="en['card.title']"
            :desc="en['card.desc']"
            country="en"
            :redirect="en['card.redirect']"
          />
          <Card
            v-if="otherLanguages.includes('vi')"
            class="cursor-pointer"
            :link="vi['card.link']"
            :title="vi['card.title']"
            :desc="vi['card.desc']"
            country="vi"
            :redirect="vi['card.redirect']"
          />
        </div>
      </div>
    </div>

    <div class="default-content min-h-screen text-2xl flex flex-col justify-center items-center z-0" v-else>
      <Intro class="items-start mb-4" :title="en['intro.title']" :desc="en['intro.desc']" :desc2="en['intro.desc2']" />
      <Card
        class="mb-4 cursor-pointer"
        :link="de['card.link']"
        :title="de['card.title']"
        :desc="de['card.desc']"
        country="de"
        :redirect="de['card.redirect']"
      />
      <Card
        class="mb-4 cursor-pointer"
        :link="en['card.link']"
        :title="en['card.title']"
        :desc="en['card.desc']"
        country="en"
        :redirect="en['card.redirect']"
      />
      <Card
        class="cursor-pointer"
        :link="vi['card.link']"
        :title="vi['card.title']"
        :desc="vi['card.desc']"
        country="vi"
        :redirect="vi['card.redirect']"
      />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import Card from '@/components/Card.vue'
import Intro from '@/components/Intro.vue'
import enValues from './locales/en.json'
import deValues from './locales/de.json'
import viValues from './locales/vi.json'

export default {
  name: 'App',
  components: {
    Card,
    Intro
  },
  data() {
    return {
      language: '',
      otherLanguages: ['en', 'de', 'vi'],
      en: enValues,
      de: deValues,
      vi: viValues
    }
  },
  created() {
    if (this.hasSupportedLanguages()) {
      if (this.$i18n) {
        this.$i18n.locale = this.language
        firebase.analytics()?.logEvent('language', { browserLanguage: this.language })
      }
    }
  },
  methods: {
    hasSupportedLanguages() {
      let isSupported = false
      const supportedLanguages = ['en', 'de', 'vi']
      for (let browserLanguage of window.navigator.languages) {
        if (browserLanguage === 'en-us') browserLanguage = 'en'
        for (let supportedLanguage of supportedLanguages) {
          if (!isSupported && browserLanguage === supportedLanguage) {
            this.language = browserLanguage
            this.removeLanguageFromSupportedLanguages(this.language)
            isSupported = true
          }
        }
      }
      return isSupported
    },
    removeLanguageFromSupportedLanguages(language) {
      const index = this.otherLanguages.indexOf(language)
      if (index !== -1) {
        this.otherLanguages.splice(index, 1)
      }
    }
  }
}
</script>

<style>
@import 'assets/theme.css';
</style>
