import Vue from 'vue'

import App from './App.vue'
import i18n from './i18n'

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDkDgA_thWGyMeTJIl7Ld9SFYNIDjwibmY",
  authDomain: "survey-mi.firebaseapp.com",
  projectId: "survey-mi",
  storageBucket: "survey-mi.appspot.com",
  messagingSenderId: "513551188263",
  appId: "1:513551188263:web:48cf123c5735ca8521a902",
  measurementId: "G-YERSZ6WPHG"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

Vue.prototype.$analytics = firebase.analytics();

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
