<template>
  <div
    class="w-screen h-screen bg-opacity-95 absolute top-0 left-0 bg-gray-900 z-10 flex flex-col items-center justify-center text-white font-bold text-xl md:text-3xl cursor-default"
  >
    {{ msg }}
    <div class="w-64" id="load">
      <img
        rel="preload"
        class="w-full h-full object-contain pointer-events-none"
        src="nyan.gif"
        alt="loading..."
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    msg: String
  },
  mounted() {
    /* 
    const dot = this.$el?.querySelector('#dot')
    setInterval(() => {
      if (dot.innerHTML === '...') {
        dot.innerHTML = ''
      } else {
        dot.innerHTML += '.'
      }
    }, 500)
 */
  }
}
</script>
<style scoped></style>
