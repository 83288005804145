<template>
  <div
    class="intro border-l-8 border-green-500 md:intro-lg max-w-3xl rounded-md overflow-hidden shadow-lg select-none bg-green-100 dark:bg-teal-900 pl-6 p-4 text-left"
  >
    <p class="text-gray-900 dark:text-gray-100 mb-2 text-lg md:text-2xl">
      {{ title }}
    </p>
    <div class="text-gray-800 dark:text-gray-200 text-sm md:text-base ">
      <p>{{ desc }}</p>
      <p>{{ desc2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    title: String,
    desc: String,
    desc2: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.intro {
  width: 21rem;
}
@media (min-width: 768px) {
  .intro {
    width: 32rem;
  }
}
</style>
